import ErrorMessage from '@components/error_message'
import Page from '@components/page'

const Custom404 = () => (
  <Page>
    <ErrorMessage />
  </Page>
)

export default Custom404
